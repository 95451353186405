import React, { PureComponent } from 'react';
import styles from './styles.module.scss';

class ContentWrapper extends PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.mainContent}>{children}</div>
      </div>
    );
  }
}

export default ContentWrapper;
