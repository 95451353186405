import React, { PureComponent } from 'react';
import Flickity from 'react-flickity-component';

class Slider extends PureComponent {
  render() {
    return <Flickity className={this.props?.className || ''} options={{autoPlay: true}} >{this.props.children}</Flickity>;
  }
}

export default Slider;
