import React, { Fragment, PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import cn from 'classnames';

import logo from 'images/topbar-logo.svg';
import backgroundImage from 'images/background.jpg';

import { Navigation } from './components/Navigation';
import { Menu } from './components/Menu';

import styles from './styles.module.scss';
import { withRouter } from 'react-router-dom';
class Header extends PureComponent {
  state = {
    modalVisible: true,
    shouldShowTopbarInMobileView: false,
    shouldShowNavigationBarBackground: false,
  };

  toggleMenu = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > 100 && !this.state.shouldShowTopbarInMobileView) {
      this.setState({
        shouldShowTopbarInMobileView: true,
        shouldShowNavigationBarBackground: true,
      });
    } else if (window.pageYOffset < 100 && this.state.shouldShowTopbarInMobileView) {
      this.setState({
        shouldShowTopbarInMobileView: false,
        shouldShowNavigationBarBackground: false,
      });
    }
  };

  goHomeOrBack = () => {
    if (this.props.location.pathname.startsWith('/projects/')) {
      this.props.history.goBack();
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    return (
      <div
        className={styles.headerWrapper}
        style={{
          backgroundImage: `${
            this.state.shouldShowNavigationBarBackground ? `url(${backgroundImage})` : 'none'
          }`,
        }}
      >
        <div className={styles.headerContentWrapper}>
          <MediaQuery maxWidth="767px">
            {(matches) =>
              matches ? (
                <Fragment>
                  <div
                    className={cn(
                      { [styles.hideBackground]: !this.state.shouldShowTopbarInMobileView },
                      styles.mobileNavWrapper
                    )}
                  >
                    {this.state.shouldShowTopbarInMobileView && (
                      <Fragment>
                        <img src={logo} alt={'logo'} onClick={this.goHomeOrBack} />
                        {this.props.projectHeader && (
                          <div className={styles.projectNavigation}>{this.props.projectName}</div>
                        )}
                      </Fragment>
                    )}
                    {!this.props.projectHeader && <Menu toggleMenu={this.toggleMenu}></Menu>}
                  </div>

                  <div
                    className={cn(styles.modal, { [styles.modalVisible]: this.state.modalVisible })}
                  ></div>
                </Fragment>
              ) : (
                <div className={styles.desktopNavWrapper}>
                  {this.state.shouldShowTopbarInMobileView && (
                    <Fragment>
                      <img src={logo} alt={'logo'} onClick={this.goHomeOrBack} />
                      {this.props.projectHeader && (
                        <div className={styles.projectNavigation}>
                          Projects / {this.props.projectName}
                        </div>
                      )}
                    </Fragment>
                  )}

                  {!this.props.projectHeader && <Navigation />}
                </div>
              )
            }
          </MediaQuery>
        </div>

        <div
          className={cn(styles.menu, { [styles.hidden]: this.state.modalVisible })}
          onClick={this.toggleMenu}
        >
          <span onClick={this.toggleMenu}>&times;</span>
          <div>
            {this.props.projectHeader ? (
              <div className={styles.projectNavigation}>Projects / {this.props.projectName}</div>
            ) : (
              <Navigation isModalUse={true} onNavItemClick={this.toggleMenu} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
