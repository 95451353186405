import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { Landing } from './scenes/Landing';

import { Project } from './components/Project';
import Firebase, { FirebaseContext } from './components/Firebase';

import { withQuicklink } from 'quicklink/dist/react/hoc.js';

import CookieConsent from 'react-cookie-consent';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';


class App extends React.Component {
  render() {
    const options = {
      origins: [],
    };
    return (
      <Fragment>
        <Router history={createBrowserHistory()}>
          <Switch>
            <Route exact path="/" component={Landing}></Route>
            <Route exact path="/about" component={Landing}></Route>
            <Route exact path="/portfolio" component={Landing}></Route>
            <Route exact path="/blog" component={Landing}></Route>
            <Route exact path="/contact" component={Landing}></Route>
            <Route
              exact
              path="/projects/:id"
              component={withQuicklink(Project, options)}
            ></Route>
          </Switch>
        </Router>

        <CookieConsent
          location="bottom"
          buttonText="Close"
          style={{ background: '#2B373B' }}
          cookieName={'cookieConsentCookie'}
          buttonStyle={{
            color: 'white',
            background: '#ff4d5a',
            borderRadius: '10px',
          }}
          expires={150}
        >
          We use cookies and other tracking technologies to improve your
          browsing experience on our website, to analyze our website traffic,
          and to understand where our visitors are coming from.
        </CookieConsent>
      </Fragment>
    );
  }
}

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root')
);
