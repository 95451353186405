import React, { Fragment, PureComponent } from 'react';

import styles from './styles.module.scss';
import { Navigation } from '../Navigation';

class Menu extends PureComponent {
  render() {
    const { toggleMenu } = this.props;
    return (
      <Fragment>
        <div className={styles.menu} onClick={toggleMenu}>
          <Navigation isMobile={true} />
        </div>
      </Fragment>
    );
  }
}

export default Menu;
