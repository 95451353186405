import app from 'firebase/app';
// eslint-disable-next-line no-unused-vars
import firestore from 'firebase/firestore';
// eslint-disable-next-line no-unused-vars
import storage from 'firebase/storage';
// eslint-disable-next-line no-unused-vars
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyATV4L9WyzL0qET6jyFiP_yr2wvD1sByHk',
  authDomain: 'mrslav-d9a33.firebaseapp.com',
  projectId: 'mrslav-d9a33',
  storageBucket: 'mrslav-d9a33.appspot.com',
  messagingSenderId: '769355492227',
  appId: '1:769355492227:web:207787710e0ba76c7164b6',
  measurementId: 'G-2R9Q7MQLG4',
};

class Firebase {
  constructor(props) {
    app.initializeApp(firebaseConfig);
    this.db = app.firestore();
    this.storage = app.storage().ref();

    app.analytics();
    // if (process.env.DEVELOPMENT) {
    //     console.log(process.env.DEVELOPMENT)
    //     this.db.useEmulator("localhost", 8080);
    // }
  }

  getProjects = async () =>
    (await this.db.collection('projects').get()).docs.map((doc) => doc.data()).filter(item => !item.hidden).sort((a, b) => {
      if(a?.createdAt > b?.createdAt){
        return 1;
      } else if(b?.createdAt > a?.createdAt){
        return -1;
      } else {
        return 0;
      }
    });

  getProject = async (id) => (await this.db.collection('projects').doc(id).get()).data();

  setData = async () => {
    const projects = await this.getProjects();
    for (const element of projects) {
      await this.db
        .collection('projects')
        .doc(element.id)
        .set({
          ...element,
        });
    }
  };

  getImagesForProject = async (id) =>
    await Promise.all(
      await (await this.storage.child(`project-images/${id}`).listAll()).items.map((item) =>
        item.getDownloadURL()
      )
    );

  getThumbnailForProject = async (id) =>
    (
      await Promise.all(
        await (
          await this.storage.child(`project-images/${id}/thumbnail`).list()
        ).items.map((item) => item.getDownloadURL())
      )
    )[0];
}

export default Firebase;
