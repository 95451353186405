import React, { createRef, Fragment, PureComponent, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

import Home from '../../components/Home/Home';
import Header from '../../components/Header/Header';
import Contact from '../../components/Contact/Contact';

import styles from './styles.module.scss';

const About = React.lazy(() => import('../../components/About/About'));
const Portfolio = React.lazy(() => import('../../components/Portfolio/Portfolio'));

const SectionContext = React.createContext('section');

const offset = 50;
class Landing extends PureComponent {
  state = {
    selectedSection: null,
  };

  sectionRefs = [createRef(), createRef(), createRef(), createRef()];

  scrollTo = (ref) => {
    window.scroll({
      top: ref.offsetTop,
      behavior: 'smooth',
    });
  };

  debounce(func, wait, immediate) {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  scrollHandler = this.debounce(() => {
    this.sectionRefs.forEach((section) => {
      if (section.current) {
        section.current.selected =
          window.scrollY >= section.current?.offsetTop - offset &&
          window.scrollY < section?.current.offsetTop + section.current?.offsetHeight - offset;
      }
    });

    //Scroll at bottom
    if (
      window.scrollY >
      this.sectionRefs[2]?.current?.offsetTop + this.sectionRefs[2]?.current?.offsetHeight * 0.66
    ) {
      this.sectionRefs[2].current.selected = false;
      this.sectionRefs[3].current.selected = true;
    }

    const selected = this.sectionRefs.find((item) => item?.current?.selected);

    selected?.current &&
      this.state.selectedSection !== selected.current.id &&
      this.setState({
        selectedSection: selected.current,
      });
  }, 100);

  scrollToInitialSection = () => {
    const section = this.sectionRefs.find(
      (sec) => sec?.current?.id.toLowerCase() === this.props.location?.pathname.split('/')[1]
    )?.current;

    if (!section) {
      const newSectionRefs = [...this.sectionRefs];
      newSectionRefs[0].current.selected = true;
      this.setState({ sectionRefs: newSectionRefs });
    } else {
      section.selected = true;

      setTimeout(() => {
        this.scrollTo(section);
      }, 700);
    }
  };

  componentDidMount = () => {
    this.scrollToInitialSection();
    window.addEventListener('scroll', this.scrollHandler);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scrollHandler);
  };

  render() {
    return (
      <Fragment>
        <SectionContext.Provider
          value={{
            sections: this.sectionRefs,
            selected: this.state.selectedSection,
            scrollTo: this.scrollTo,
          }}
        >
          <div className={styles.landingWrapper} ref={this.ref}>
            <Header />
            <div className={styles.contentWrapper}>
              <div id="home" ref={this.sectionRefs[0]}>
                <Home />
              </div>
              <Suspense
                fallback={
                  <div className={styles.loaderWrapper}>
                    <div className={styles.loader}></div>
                    <div className={styles.loadingText}>Loading</div>
                  </div>
                }
              >
                <div id="about" ref={this.sectionRefs[1]}>
                  <About />
                </div>

                <div id="portfolio" ref={this.sectionRefs[2]}>
                  <div>
                    <Portfolio />
                  </div>
                </div>

                <div id="contact" ref={this.sectionRefs[3]}>
                  <Contact />
                </div>
              </Suspense>
            </div>
          </div>
        </SectionContext.Provider>
      </Fragment>
    );
  }
}

Landing.propTypes = {};

export default withRouter(Landing);
export { SectionContext };
