import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import Carousel from 'react-spring-3d-carousel';

import backgroundImage from 'images/background.jpg';
import arrow from 'images/icons/arrow.svg';
import logo from 'images/topbar-logo.svg';

import styles from './styles.module.scss';
import { Header } from '../Header';
import MediaQuery from 'react-responsive';
import { ContentWrapper } from '../ContentWrapper';

import ReactHtmlParser from 'react-html-parser';

class Project extends PureComponent {
  state = {
    isLoading: true,
    goToSlide: 0,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { id } = this.props.match.params;
    this.projectDetails = await this.props.firebase.getProject(id);
    this.projectImages = await this.props.firebase.getImagesForProject(id);

    this.slides = this.projectImages.map((url, index) => ({
      key: index,
      content: <img src={url} alt={this.projectDetails.name}></img>,
      onClick: () => this.setState({ goToSlide: index }),
    }));
    this.setState({ isLoading: false });
  }

  toggleNextImage = () => {
    this.setState({ goToSlide: (this.state.goToSlide + 1) % this.projectImages.length });
  };

  togglePreviousImage = () => {
    this.setState({ goToSlide: (this.state.goToSlide - 1) % this.projectImages.length });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Fragment>
        <div
          className={styles.projectWrapper}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className={styles.projectHeader}>
            <img src={logo} alt={'logo'} onClick={this.goBack} />
            <MediaQuery maxWidth="767px">
              {(matches) =>
                matches ? (
                  <span>{this.projectDetails?.name}</span>
                ) : (
                  <span>Projects / {this.projectDetails?.name}</span>
                )
              }
            </MediaQuery>
          </div>
          <Header projectHeader={true} projectName={this.projectDetails?.name}></Header>

          {!this.state.isLoading && (
            <div className={styles.carouselWrapper}>
              <Carousel offsetRadius={2} slides={this.slides} goToSlide={this.state.goToSlide} />
              <div className={styles.arrowLeft} onClick={this.togglePreviousImage}>
                <img src={arrow} alt="Arrow left" />
              </div>
              <div className={styles.arrowRight} onClick={this.toggleNextImage}>
                <img src={arrow} alt="Arrow left" />
              </div>
            </div>
          )}

          <div className={styles.footerWrapper}>
            <div className={styles.scroll}>
              <div>
                <span></span>
              </div>
            </div>
            <div className={styles.projectDetails}>Project Details</div>
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <ContentWrapper>
            <h1>{this.projectDetails?.name}</h1>
            <span>{ReactHtmlParser(this.projectDetails?.description)}</span>
          </ContentWrapper>
        </div>
      </Fragment>
    );
  }
}

export default withFirebase(withRouter(Project));
