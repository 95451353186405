import React, { Fragment, PureComponent } from 'react';
import MediaQuery from 'react-responsive';

import backgroundImage from 'images/background.jpg';
import logo from 'images/logo.svg';
import illustrations from 'images/music-developer-designer.svg';
import musician from 'images/piano.svg';
import developer from 'images/developer.svg';
import designer from 'images/designer.svg';

import LazyLoad from 'react-lazyload';

import { ContentWrapper } from '../ContentWrapper';
import { Slider } from '../Slider';

import styles from './styles.module.scss';

class Home extends PureComponent {
  render() {
    return (
      <div className={styles.homeWrapper} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <ContentWrapper>
          <div className={styles.homeContentWrapper}>
            <img src={logo} className={styles.logo} alt="Miroslav Brnic" />

            <div className={styles.illustrationsWrapper}>
              <MediaQuery minWidth="768px">
                {(matches) =>
                  matches ? (
                    <Fragment>
                      <img
                        className={styles.illustrations}
                        src={illustrations}
                        alt="Musician, Developer, Designer"
                      />
                    </Fragment>
                  ) : (
                    <div className={styles.sliderWrapper}>
                      <Slider className={'homeSlider'}>
                        <div className={styles.sliderItem}>
                          <LazyLoad>
                            <img src={musician} alt="Musician" />
                          </LazyLoad>
                        </div>

                        <div className={styles.sliderItem}>
                          <LazyLoad>
                            <img src={developer} alt="Developer" />
                          </LazyLoad>
                        </div>
                        <div className={styles.sliderItem}>
                          <LazyLoad>
                            <img src={designer} alt="Designer" />
                          </LazyLoad>
                        </div>
                      </Slider>
                    </div>
                  )
                }
              </MediaQuery>
            </div>
          </div>
        </ContentWrapper>
      </div>
    );
  }
}

export default Home;
