import React, { PureComponent } from 'react';

import backgroundImage from 'images/contact_background.jpg';
import downloadButton from 'images/icons/download_icon.svg';
import emailIcon from 'images/icons/email_icon.svg';

import gitlabIcon from 'images/icons/gitlab_icon.svg';
import githubIcon from 'images/icons/github_icon.svg';
import linkedinIcon from 'images/icons/linkedin_icon.svg';

import cv from 'images/cv.pdf';

import { ContentWrapper } from '../ContentWrapper';

import styles from './styles.module.scss';

class Contact extends PureComponent {
  render() {
    return (
      <div className={styles.contactWrapper} style={{ backgroundImage: `url(${backgroundImage})` }}>
        <ContentWrapper>
          <div className={styles.contactContentWrapper}>
            <div className={styles.labelsWrapper}>
              <h1>Let's get in touch</h1>
              <h2>
                We can have a talk in 3 different languages. English, Hungarian and Serbian. <br />
                Choose wisely.
              </h2>

              <div className={styles.buttonsWrapper}>
                <div className={styles.cvDownloadButton}>
                  <a href={cv} download="Brnic_Miroslav_CV">
                    <span>Download my CV</span>
                    <img src={downloadButton} alt="CV Download button" />
                  </a>
                </div>
                <div className={styles.emailButton}>
                  <a href="mailto:miroslav.brnic@gmail.com">
                    <img src={emailIcon} alt="Send me an e-mail" />
                    <span>Send me a message</span>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.footerWrapper}>
              <span>Check out my work</span>
              <div className={styles.footerIcons}>
                <a href="https://gitlab.com/miroslavb45" target="_blank" rel="noreferrer">
                  <img src={gitlabIcon} alt="Gitlab icon" />
                </a>
                <a href="https://github.com/miroslavb45" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Github icon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/miroslav-brnic/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} alt="LinkedIn icon" />
                </a>
              </div>
              <span>
                Copyright © {new Date().getFullYear()} Miroslav Brnic. All Rights Reserved.
              </span>
            </div>
          </div>
        </ContentWrapper>
      </div>
    );
  }
}

export default Contact;
