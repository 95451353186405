import React, { memo, useContext, useEffect } from 'react';
import cn from 'classnames';

import { withRouter } from 'react-router-dom';
import { SectionContext } from '../../../../scenes/Landing/Landing';

import styles from './styles.module.scss';

const Navigation = (props) => {
  const context = useContext(SectionContext);

  const handleSectionClick = (section) => {
    props.onNavItemClick && props.onNavItemClick();
    !props.isMobile && context.scrollTo(section);
  };

  // Used for replacing url on scroll
  useEffect(() => {
    if (context.selected && !props.isModalUse) {
      if (context.selected.id !== 'home') {
        if (props.location?.pathname.split('/')[1] !== context.selected.id) {
          props.history.replace(`/${context.selected.id}`);
        }
      } else if (context.selected.id === 'home') {
        if (props.location?.pathname !== '/') {
          props.history.replace('/');
        }
      }
    }
  }, [context.selected, props.history, props.location, props.isModalUse]);

  return (
    <div
      className={cn({
        [styles.navItemsWrapper]: !props.isModalUse,
        [styles.modalItemsWrapper]: props.isModalUse,
      })}
    >
      <ul>
        {context.sections
          .map((section) => section.current)
          .filter((section) => section)
          .map((section, index) => (
            <li key={`${section?.id}-${index}`}>
              {props.isModalUse ? (
                <div
                  className={cn(styles.modalItem, {
                    [styles.modalItemActive]: section.selected,
                  })}
                >
                  <div onClick={handleSectionClick.bind(this, section)}>
                    {section && section?.id.charAt(0).toUpperCase() + section?.id.slice(1)}
                  </div>
                </div>
              ) : (
                <div
                  className={cn(styles.navItem, styles.line, {
                    [styles.active]: section.selected,
                  })}
                  onClick={handleSectionClick.bind(this, section)}
                >
                  <span>
                    {section && section?.id.charAt(0).toUpperCase() + section?.id.slice(1)}
                  </span>
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default memo(withRouter(Navigation));
